<template>
  <vx-card :title="title">
    <!-- filters -->
    <div class="vx-row">
      <div class="vx-col w-1/2" v-show="isCustomer">
        <label class="vs-input--label">Status</label>
        <multiselect
          class="selectExample"
          v-model="filters.selectedStatus"
          :options="filters.statusData"
          :show-labels="true"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-1/2" v-show="isAdmin">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="filters.selectedTerritory"
          :options="filters.territoryData"
          @search-change="handleOptionTerritoriesSearch"
          @select="handleOptionTerritoriesSelect"
          placeholder="Type to search"
          track-by="id"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.name }} - {{ props.option.code }}</span
              >
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.name }} - {{ props.option.code }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-1/2" v-show="isAdmin">
        <label class="vs-input--label">Salesman</label>
        <multiselect
          class="selectExample"
          v-model="filters.selectedSalesman"
          :options="filters.salesmanData"
          :searchable="false"
          @search-change="handleOptionSalesmanSearch"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">
                {{ props.option.personal ? props.option.personal.name : "" }}
              </span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">
                {{
                  props.option.personal ? props.option.personal.name : ""
                }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date Start</label>
        <vs-input
          class="w-full"
          type="date"
          v-model="table.dateStart"
          :max="table.dateEnd"
        />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Date End</label>
        <vs-input
          class="w-full"
          type="date"
          v-model="table.dateEnd"
          :min="table.dateStart"
        />
      </div>

      <!-- button -->
      <div class="vx-col w-full">
        <vs-button
          class="mt-2"
          type="border"
          color="primary"
          @click="btnSearchFilter"
          >Search</vs-button
        >
      </div>
    </div>

    <div class="vx-row my-4">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>UUID</vs-th>
            <vs-th>Date</vs-th>
            <vs-th>Amount</vs-th>
            <vs-th>Salesman</vs-th>
            <vs-th>Notes</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  title="Detail"
                  icon="EyeIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                  @click.stop="handleDetail(data[indextr].id)"
                />
              </vs-td>
              <vs-td>
                payment: {{ data[indextr].customer_order_payment_uuid }}
                <br />
                order: {{ data[indextr].customer_order_uuid }}
              </vs-td>
              <vs-td>
                payment :
                {{ data[indextr].customer_order_payment_date | formatDate }}
                <br />
                due : {{ data[indextr].customer_order_due_date | formatDate }}
              </vs-td>
              <vs-td>
                total : {{ data[indextr].customer_order_payment_total }}
                <br />
                outstanding : {{ data[indextr].customer_order_outstanding }}
              </vs-td>
              <vs-td>
                {{ data[indextr].personal_name }}
              </vs-td>
              <vs-td>
                ref no :
                {{ data[indextr].customer_order_payment_reference_number }}
                <br />
                description :
                {{ data[indextr].customer_order_payment_description }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      title: "Payment",
      baseUrl: "/api/sfa/v1/payment/customer-order-payment/all",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      id: null,
      table: this.tableDefaultState(),
      filters: {
        selectedSalesman: null,
        salesmanData: [],
        selectedStatus: null,
        statusData: [],
        oldSalesmanData: [],
        selectedTerritory: null,
        territoryData: [],
      },
      isCustomer: false,
      isAdmin: false,
    };
  },
  async mounted() {
    await this.handleOptionTerritories();
    await this.handleOptionStatuses();
    this.isCustomer = await this.checkIsCustomer();
    this.isAdmin = await this.checkIsAdmin();
  },
  methods: {
    async handleOptionStatuses() {
      this.$vs.loading();
      let url = `/api/sfa/v1/payment/customer-order-payment/statuses`;
      let resp = await this.$http.get(url);
      if (resp.status == "OK") {
        this.filters.statusData = resp.data;
      }
      this.$vs.loading.close();
    },
    async btnSearchFilter() {
      let params = {
        date_start: this.filters.dateStart,
        date_end: this.filters.dateEnd,
        status: this.filters.selectedStatus,
      };

      if (this.filters.selectedTerritory) {
        params.territory_id = this.filters.selectedTerritory.id;
      }

      if (this.filters.selectedSalesman) {
        params.salesman_personal_id = this.filters.selectedSalesman.personal_id;
      }

      let isCustomer = await this.checkIsCustomer();
      // check is customer
      if (isCustomer) {
        params.personal_id = this.$store.state.user.currentUser.personal_id;
      }

      await this.getData(params);
    },
    async handleOptionTerritoriesSelect(row, id) {
      await this.handleOptionSalesman(row.id);
    },
    async handleOptionSalesman(territory_id) {
      this.filters.selectedSalesman = null;
      this.$vs.loading();
      let url = `/api/sfa/v1/personal-territory?territory_id=${territory_id}&length=999`;
      let resp = await this.$http.get(url);
      if (resp.status == "OK") {
        this.filters.salesmanData.push({
          personal: {
            name: "All",
          },
          id: 0,
        });
        for (let index = 0; index < resp.data.records.length; index++) {
          this.filters.salesmanData.push(resp.data.records[index]);
        }
        // this.filters.salesmanData = resp.data.records;
        this.filters.oldSalesmanData = resp.data.records;
      }
      this.$vs.loading.close();
    },
    async handleOptionSalesmanSearch(query, id) {
      // filters data by personal
      let data = await this.filters.salesmanData.filter((item) => {
        if (item.personal && item.personal.name) {
          if (item.personal.name.toLowerCase().includes(query.toLowerCase())) {
            return item;
          }
        }
      });

      setTimeout(() => {
        if (data.length == 0 || query == "") {
          this.filters.salesmanData = this.filters.oldSalesmanData;
        } else {
          this.filters.salesmanData = data;
        }
      }, 100);
    },
    async handleOptionTerritories() {
      const company_id = this.$store.state.user.currentUser.company_id;

      let url = `/api/sfa/v1/territory?company_id=${company_id}`;
      let resp = await this.$http.get(url);
      if (resp.status == "OK") {
        this.filters.territoryData = [{ name: "ALL", code: "", id: 0 }];
        for (let index = 0; index < resp.data.records.length; index++) {
          this.filters.territoryData.push(resp.data.records[index]);
        }
        // this.filters.territoryData = resp.data.records;
      }
    },
    async handleOptionTerritoriesSearch(query, id) {
      this.$vs.loading();
      const company_id = this.$store.state.user.currentUser.company_id;

      let url = `/api/sfa/v1/territory?company_id=${company_id}&search=${query}`;
      let resp = await this.$http.get(url);
      if (resp.status == "OK") {
        this.filters.territoryData = [{ name: "ALL", code: "", id: 0 }];
        for (let index = 0; index < resp.data.records.length; index++) {
          this.filters.territoryData.push(resp.data.records[index]);
        }
        // this.filters.territoryData = resp.data.records;
      }
      this.$vs.loading.close();
    },
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        territory_id: null,
        salesman_personal_id: null,
        dateStart: null,
        dateEnd: null,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.territory_id = this.filters.selectedTerritory
        ? this.filters.selectedTerritory.id
        : null;
      this.table.salesman_personal_id = this.filters.selectedSalesman
        ? this.filters.selectedSalesman.personal_id
        : null;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.table.territory_id = this.filters.selectedTerritory
        ? this.filters.selectedTerritory.id
        : null;
      this.table.salesman_personal_id = this.filters.selectedSalesman
        ? this.filters.selectedSalesman.personal_id
        : null;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.table.territory_id = this.filters.selectedTerritory
        ? this.filters.selectedTerritory.id
        : null;
      this.table.salesman_personal_id = this.filters.selectedSalesman
        ? this.filters.selectedSalesman.personal_id
        : null;
      this.getData();
    },
    async getData(params) {
      this.$vs.loading();
      // param table without data
      let paramTable = {
        ...this.table,
        data: [],
      };
      let url = this.baseUrl;
      let resp = await this.$http.get(url, {
        params: {
          ...paramTable,
          ...params,
        },
      });

      if (resp.status == "OK") {
        this.table.total = resp.data.total_record;
        this.table.totalPage = resp.data.total_page;
        this.table.totalSearch = resp.data.total_record_search;
        this.table.length = resp.data.total_record_per_page;
        this.table.data = resp.data.records;
        this.setStartEnd();
      }

      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDetail(id) {
      // push to router detail
      this.$router.push({
        name: "payment-detail",
        params: { id: id },
      });
    },

    // check role
    async checkIsCustomer() {
      let role = await this.$store.state.user.currentUser.roles.find(
        (item) => item.name == "customer"
      );

      if (role) {
        return true;
      }

      return false;
    },
    async checkIsAdmin() {
      let role = await this.$store.state.user.currentUser.roles.find(
        (item) => item.name == "admin"
      );

      if (role) {
        return true;
      }

      return false;
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
